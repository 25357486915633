.Label{
    font-weight: bolder !important;
    color: #2EA2EC;
}

.Row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 55%;
    offset: 12px;
    margin: auto;
}
.FormItem{
    /* background-color: turquoise; */
    width: 40%;
    text-align: center;
    margin: auto;
    backdrop-filter: blur(2px);
}
.InvalidLabel{
    font-weight: bolder !important;
    color: red;
}
.LandLogo{
    width : 7rem;
    height : 6.5rem; 
    margin : auto;
    transition: 0.3sec;
}

.Input{
    width: 90%;
    background-color: white;
    border-color: white;
    /* box-Shadow: 0 8px 16px 0 rgba(0,0,0,0.2); */
    padding: 0.5rem 1rem !important;
}

.Input:focus{
    background-color: white;
    border-color: #2EA2EC;
    box-Shadow: 0 8px 16px 0 rgba(32, 120, 179, 0.2);
    padding: 0.5rem 1rem !important;
}
.InvalidInput{
    width: 100%;
    background-color: white;
    padding: 0.5rem 1rem !important;
    box-Shadow: 0 8px 16px 0 rgba(223, 32, 32, 0.527);
}
.InvalidInput:focus{
    background-color: white;
    border-color: #2EA2EC;
    box-Shadow: 0 8px 16px 0 rgba(32, 120, 179, 0.2);
    padding: 0.5rem 1rem !important;
}

.SignUpDiv{
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem !important;
    justify-content: center !important;
    margin: auto !important;
    
}

.SignInSignUpLogo{
    text-align: center;
    width : 6rem;
    height : 6rem; 
    margin : auto;
    margin-bottom: 2rem;
    justify-content: center;
}
.SignInSignUpbg{
   
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background:#f9f9f9;
    background-repeat: no-repeat;
    background-size: cover;
    /* filter: brightness(0.9); */
}
.SignupFormbg{
    /* background-color: rgba(255, 255, 255, 0.33); */
    /* box-shadow: rgba(0,0,255,0.33); */
    /* backdrop-filter: blur(3px); */
    /* padding: 0px 16px 16px 16px;      */
    border-radius: 16px;
    /* animation: backgroundAnimation 20s infinite linear; */
}

@keyframes backgroundAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
.ant-form ant-form-horizontal SignInPage_SignupFormbg__328Q9{
    padding: 0px 16px 16px 16px;  
}

.Create{
    text-align: center;
    white-space: nowrap;
    color: #000000;
    width: 80%;
    font-weight : 500;
    font-family: Montserrat;
    font-size: 0.8rem;
    letter-spacing: -0.54px;
    line-height: 2rem;
    border-radius: 9px;
    background-color: inherit;
    border: none;
    outline: none;
    margin-top: 1rem !important;
}
.Create:hover{
    background-color: white;
    box-shadow: 2px 2px 4px 4px rgba(58, 57, 57, 0.103), 0 3px 6px 0 rgba(112, 111, 111, 0.1);
}
.Create:focus{
    outline: none;
}

.NewButton{
    text-align: center;
    white-space: nowrap;
    color: #2EA2EC;
    font-weight : 900;
    font-family: Montserrat;
    font-size: 0.6rem;
    letter-spacing: -0.54px;
    border-radius: 9px;
    background-color: inherit;
    border: dashed;
    border-width: 1.5px;
    box-sizing: border-box;
    border-color: #2EA2EC;
    outline: none;
    padding: 0.5rem 2rem;
    background-color: white;
    margin-top: 3rem;
}

.SelectedButton{
    text-align: center;
    white-space: nowrap;
    color: white;
    font-weight : 900;
    font-family: Montserrat;
    font-size: 0.6rem;
    letter-spacing: -0.54px;
    border-radius: 9px;
    box-sizing: border-box;
    background-color: inherit;
    border: solid;
    border-color: #2EA2EC;
    outline: none;
    padding: 0.5rem 2rem;
    background-color: #2EA2EC;
    margin-top: 3rem;
}


@media only screen and (min-width: 500px)  and (orientation:portrait) {
    .SignUpDiv{
        margin-bottom: 2rem !important;
    }
    
    .SignInSignUpLogo{
        width : 7rem;
        height : 7rem; 
        /* margin-top: 7rem; */
    }
        
    .Create{
        width: 70%;
        font-size: 1.2rem;
        line-height: 2rem;
    }
    
}

@media only screen and (min-width: 500px)  and (orientation:landscape) {
    .SignUpDiv{
        margin-bottom: 2rem !important;
    }
    
    .SignInSignUpLogo{
        width : 7rem;
        height : 7rem; 
        margin-top: 15rem;
    }
        
    .Create{
        width: 70%;
        font-size: 1.2rem;
        line-height: 2rem;
    }
    
}

@media only screen and (min-width: 768px) and (orientation:portrait) {
    .SignUpDiv{
        margin-bottom: 2rem !important;
    }
    
    .SignInSignUpLogo{
        width : 8rem;
        height : 8rem; 
        margin-top: 0rem;
    }
    
    .Create{
        width: 50%;
        font-size: 1.2rem;
        line-height: 2rem;
    }

}

@media only screen and (min-width: 768px) and (orientation:landscape) {
    .SignUpDiv{
        margin-bottom: 2rem !important;
    }
    
    .Input{
        width: 60%;
    }
    
    .InvalidInput{
        width: 60%;
    }

    .SignInSignUpLogo{
        width : 7rem;
        height : 7rem; 
        margin-top: 0rem;
    }
    
    .Create{
        width: 40%;
        font-size: 1rem;
        line-height: 2rem;
        margin-top: 2rem !important;
    }

}

@media only screen and (min-width: 897px) {
    .SignUpDiv{
        margin-bottom: 2rem !important;
    }
    
    .SignInSignUpLogo{
        width : 6rem;
        height : 6rem; 
        margin-top: 0rem;
    }
        
    .Create{
        width: 50%;
        font-size: 1.2rem;
        line-height: 2rem;
        margin-top: 3rem !important;
    }

}

@media only screen and (min-width: 992px) {
    .SignUpDiv{
        margin-bottom: 2rem !important;
    }
    
    .Input{
        width: 60%;
    }

    .InvalidInput{
        width: 60%;
    }

    .SignInSignUpLogo{
        width : 8rem;
        height : 8rem; 
        margin-top: 0rem;
    }
    
    .Create{
        width: 40%;
        background-color: white;
        font-size: 1.2rem;
        line-height: 3rem;
        margin-top: 3rem !important;
    }

    .Row{
        width: 55%;
    }

    .NewButton{
        font-weight : 900;
        font-size: 0.9rem;
        padding: 0.5rem 2rem;
    }

    .SelectedButton{
        font-weight : 900;
        font-size: 0.9rem;
        padding: 0.4rem 2rem;
    }

}

@media only screen and (min-width: 1200px) {
    .Input{
        width: 50%;
    }
    
    .InvalidInput{
        width: 50%;
    }
    .SignInSignUpLogo{
        width : 8rem;
        height : 8rem; 
        margin-top: 0rem;
    }
        
    .Create{
        width: 35%;
        background-color: white;
        font-size: 1.2rem;
        line-height: 3rem;
        margin-top: 3rem !important;
    }

    .Row{
        width: 45%;
    }

}

@media only screen and (min-width: 1400px) {
    .SignUpDiv{
        margin-bottom: 2rem !important;
    }
    
    .Input{
        width: 50%;
    }

    .InvalidInput{
        width: 50%;
    }

    .SignInSignUpLogo{
        width : 10rem;
        height : 10rem; 
        margin-top: 0rem;
    }

    .Create{
        width: 40%;
        font-size: 1.4rem;
        line-height: 2.6rem;
    }

    .NewButton{
        margin-top: 3rem;
    }

    .SelectedButton{
        margin-top: 3rem;
    }

}

@media only screen and (min-width: 1600px) {
    .SignUpDiv{
        margin-bottom: 2rem !important;
    }
    
    .Input{
        width: 50%;
    }

    .InvalidInput{
        width: 50%;
    }

    .SignInSignUpLogo{
        width : 9rem;
        height : 9rem; 
        margin-top: 0rem;
    }
    
    .Create{
        width: 40%;
        font-size: 1.5rem;
        line-height: 2.3rem;
    }

    
}