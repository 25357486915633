.Main {
    text-align: center !important;
    justify-content: center !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Montserrat', sans-serif;
    min-height: 100vh !important;
    margin: 0px !important;
    padding: 0px !important;
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    /* background-color: blueviolet; */
}
  
  
  
  