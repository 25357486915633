.pie{
    height: 16rem;
    width: 60rem;
    text-align: center;
    background-color: transparent;
}

.line{
    height: 16rem;
    width: 50rem;
    text-align: center;
    background-color: transparent;
}